import createStore from "zustand";

// Custom data tabs by "id" props
export const tabsData = {
  MyClass: [
    {
      id: "di-pelajari",
      label: "Dipelajari",
      title: "Lanjutkan Kursusmu",
      value: "on_progress",
    },
    {
      id: "kursus-selesai",
      label: "Kursus Selesai",
      title: "Kursus yang Kamu Ambil",
      value: "finished",
    },
    // { id: "riwayat-transaksi", label: "Riwayat Transaksi", title: "", value: null },
  ],
  MyClassPlus: [
    {
      id: "di-pelajari",
      label: "Dipelajari",
      title: "Lanjutkan Kursusmu",
      value: "on_progress",
    },
    {
      id: "kursus-selesai",
      label: "Kursus Selesai",
      title: "Kursus yang Kamu Ambil",
      value: "finished",
    },
  ],
};

export type TabItem = {
  id?: string;
  label?: string;
  title?: string;
  value?: string;
};

export type TabsStore = {
  // state
  selectedTab: TabItem;
  currentData: TabItem[];

  // actions
  setSelectedTab: (tab: TabItem) => void;
  setCurrentData: (id: string) => void;
};

export const useTabsStore = createStore<TabsStore>((set) => ({
  // initial state
  currentData: [],
  selectedTab: {},

  // actions
  setSelectedTab(tab) {
    set({
      selectedTab: tab ? tab : tabsData[0],
    });
  },
  setCurrentData(id) {
    set({
      currentData: tabsData[id],
    });
  },
}));
