import { useSystemStore } from "components/PageHeader/systemStore";
import React from "react";

type Props = {
  size?: number;
  color?: string;
  className?: string;
};

export const IconBell: React.FC<Props> = ({
  size = 14,
  color = "#FFFFFF",
  className = "fill-purple80_to_white",
}) => {
  const theme = useSystemStore((state) => state.theme);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 27"
      xmlns="http://www.w3.org/2000/svg"
      // className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3333 2.00002C12.3333 1.26364 11.7364 0.666687 11 0.666687C10.2636 0.666687 9.66668 1.26364 9.66668 2.00002V3.44396C5.88306 4.07874 3.00001 7.36936 3.00001 11.3334V17.1926C3.00001 17.4559 2.92209 17.7132 2.77608 17.9322L0.557278 21.2604C0.284517 21.6696 0.259087 22.1956 0.491113 22.6292C0.723138 23.0627 1.17495 23.3334 1.66668 23.3334H20.3333C20.8251 23.3334 21.2769 23.0627 21.5089 22.6292C21.7409 22.1956 21.7155 21.6696 21.4427 21.2604L19.2239 17.9322C19.0779 17.7132 19 17.4559 19 17.1926V11.3334C19 7.36936 16.117 4.07874 12.3333 3.44396V2.00002ZM5.66668 11.3334C5.66668 8.38784 8.05449 6.00002 11 6.00002C13.9455 6.00002 16.3333 8.38784 16.3333 11.3334V17.1926C16.3333 17.9823 16.5671 18.7543 17.0051 19.4114L17.842 20.6667H4.15803L4.99488 19.4114C5.43293 18.7543 5.66668 17.9823 5.66668 17.1926V11.3334ZM11 26.6667C13 26.6667 13.6667 25.3334 13.6667 24.6667H8.33334C8.33334 25.3334 9.00001 26.6667 11 26.6667Z"
        fill={theme == "dark" ? "#FFFFFF" : color}
      />
    </svg>
  );
};
