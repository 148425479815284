import { useApi } from "lib/apiFetcher";

export const useHomeFeaturedInstitution = () =>
  useApi("get", "/featured-institution");
export const useHomeTopInstitutions = () => useApi("get", "/top-institutions");
export const useHomeFeaturedCategories = () =>
  useApi("get", "/featured-categories");
export const useHomeYourInfo = () => useApi("get", "/static-pages");
export const useHomeRecommendation = () => useApi("get", "/recomended-courses");
export const useMyClass = () => useApi("get", "/profile/my-class");

//@ts-ignore
export const useHomepageCourse = () => useApi("get", "/homepage-course");
export const useHomepageDetailCourse = (additionalKey: string) =>
  useApi("get", "/homepage-course/{slug}", additionalKey);

// ======== Notification API ======== //
export const useGetNotificationApi = () => useApi("get", "/notification");
