import React from "react";

type Props = {
  size?: number;
  className?: string;
};

const IconApperance: React.FC<Props> = ({
  size = 24,
  className = "fill-neutral-80",
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="fill-neutral-80"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.2151 20.3306C12.1436 20.3324 12.0719 20.3333 12 20.3333C7.39763 20.3333 3.66667 16.6024 3.66667 12C3.66667 7.39763 7.39763 3.66667 12 3.66667C12.0607 3.66667 12.1212 3.66732 12.1816 3.66861C12.6896 3.67946 13.1865 3.7358 13.6683 3.83369C11.6067 5.93692 10.3333 8.81922 10.3333 12C10.3333 15.1808 11.6067 18.0631 13.6683 20.1663C13.1971 20.262 12.7115 20.318 12.2151 20.3306ZM15.4737 19.577C13.3466 17.7432 12 15.0289 12 12C12 8.97115 13.3466 6.2568 15.4737 4.42298C18.3417 5.73998 20.3333 8.63741 20.3333 12C20.3333 15.3626 18.3417 18.26 15.4737 19.577ZM17 3.33782C19.989 5.06687 22 8.29859 22 12C22 15.7014 19.989 18.9331 17 20.6622C16.9925 20.6665 16.9851 20.6708 16.9776 20.6751C16.4159 20.9981 15.8199 21.2681 15.1964 21.4783C15.1225 21.5032 15.0483 21.5272 14.9737 21.5504C14.0343 21.8426 13.0355 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C13.1178 2 14.1927 2.18339 15.1964 2.52173C15.1964 2.52173 15.1964 2.52173 15.1964 2.52173M17 3.33782C16.435 3.01101 15.8351 2.73789 15.2073 2.52542L17 3.33782Z"
        // fill="black"
      />
    </svg>
  );
};

export default IconApperance;
