import createStore from "zustand";

export type ModalStore = {
  // state
  isShow: boolean | false;
  isShowModal: boolean;

  // actions
  showModalReferral: (item: boolean) => void;
  closeModalReferral: () => void;
  openModal: (value: boolean) => void;
};

export const useModalStore = createStore<ModalStore>((set) => ({
  // state
  isShow: false,
  isShowModal: false,

  // actions
  showModalReferral(item) {
    set({ isShow: item });
  },
  closeModalReferral() {
    set({ isShow: false });
  },
  openModal(value) {
    set({ isShowModal: value });
  },
}));
