import { useTrackScreen } from "lib/amplitude";
import Container, { ContainerDesktop } from "components/Container";
import { PageHeader } from "components/PageHeader/PageHeader";
import FieldWithBorder from "components/Form/FieldWithBorder";
import { CardNotFound } from "components/Card/CardNotFound";
import Link from "next/link";
import { trackEvent } from "lib/amplitude";
import { useEffect, useRef, useState } from "react";
import { useGetNotificationApi } from "modules/Home/homeApi";
import Button from "components/Button";
import { useIsDesktop } from "lib/device";
import { PageHeaderDesktop } from "components/PageHeader/PageHeaderDesktop";
import { useRouter } from "next/router";
import { MoonLoader } from "react-spinners";
import { useNotifStore } from "./notifStore";
import { paths } from "lib/__generatedApiSpec";
import { OpReturnType } from "openapi-typescript-fetch";
import { generateParams } from "lib/helper";
import { useSystemStore } from "components/PageHeader/systemStore";

type NotifData = OpReturnType<paths["/notification"]["get"]>;

export const NOTIF_PER_PAGE = 7;

const NotificationContent: React.FC<{
  theme?: string;
  isDropdown?: boolean;
}> = ({ theme = "", isDropdown = false }) => {
  const themeMode = useSystemStore((state) => state.theme);
  const notifApi = useGetNotificationApi();
  const [currData, setCurrData] = useState(null);
  const [allNotif, setAllNotif] = useState(null);
  const [page, setPage] = useState(1);
  const [isLoader, setIsLoader] = useState(true);

  const { allNotification, setAllNotifStore } = useNotifStore((state) => ({
    allNotification: state.allNotification,
    setAllNotifStore: state.setAllNotif,
  }));

  const isMounted = useRef(false);
  const notFoundProps = {
    title: "Tidak Ada Notifikasi",
    message: "Setiap notifikasi baru akan muncul di halaman ini",
  };

  //@ts-ignore
  const refactorNotifData = (arr: NotifData["data"]) => {
    setCurrData(arr);
    const allRefactorNotif =
      arr && arr.length > 0
        ? arr.map((el) => {
            return {
              id: el.id,
              title: el.updated_at,
              description: el.notification.title,
              slug: el.notification?.slug || el.notification?.id || "",
              tag: {
                code: el.notification_type.code,
                type: el.notification_type.name,
                isActive: !el.is_read,
              },
            };
          })
        : [];

    setAllNotif(isDropdown ? allRefactorNotif.slice(0, 4) : allRefactorNotif);
    setIsLoader(false);
  };

  useEffect(() => {
    if (isMounted.current) {
      setTimeout(() => {
        if (allNotification) {
          // console.log("allNotification:", allNotification);
          refactorNotifData(allNotification.data);
        }
      }, 2000);
    } else {
      isMounted.current = true;
    }
  }, [allNotification]);

  const isDesktop = useIsDesktop();
  const router = useRouter();

  const isUsingButtonViewAll =
    isDesktop && isDropdown && allNotif && allNotif.length > 0;

  const onClickLoadMore = () => {
    trackEvent({
      event: "notification_more_a",
    });

    notifApi.doFetch(
      {
        limit: NOTIF_PER_PAGE,
        page: page + 1,
      },
      {
        onSuccess(data) {
          setPage((p) => p + 1);
          const all = [...currData, ...data?.data];
          setCurrData(all);
          refactorNotifData(all);
        },
      }
    );
  };

  return (
    <div
      className={
        allNotif && allNotif.length == 0 ? "h-fit flex flex-col" : theme
      }
    >
      {isLoader ? (
        <div className="flex w-full items-center justify-center h-[80vh]">
          <MoonLoader
            loading={isLoader}
            color={themeMode == "dark" ? "#FFFFFF" : "#817E8C"}
          />
        </div>
      ) : (
        <>
          {isDropdown && isDesktop && (
            <div className="m-20 mt-0 text-left text-h4 font-medium text-purple-80">
              Notifikasi
            </div>
          )}

          {allNotif && allNotif.length > 0 ? (
            allNotif.map((elm) => {
              let utmQueries = {
                utm_source: "in-app",
                utm_medium: "notification-page",
                utm_campaign: elm.description,
              };
              let queries = {};

              switch (elm.tag.code) {
                case "transaction":
                  queries = {
                    pathname: "/transactions/[id]",
                    query: {
                      ...utmQueries,
                      id: elm.slug,
                      notificationId: elm.id,
                      url: "notification",
                    },
                  };
                  break;

                case "special-course":
                  queries = {
                    pathname: "/course/[courseSlug]",
                    query: {
                      ...utmQueries,
                      courseSlug: elm.slug,
                      notificationId: elm.id,
                    },
                  };
                  break;

                case "course":
                  (utmQueries as any).courseSlug = elm.slug;
                  (utmQueries as any).notificationId = elm.id;
                  const param = generateParams(utmQueries);
                  queries = {
                    pathname: "/r/[page]",
                    query: {
                      ...utmQueries,
                      page: `/course/${elm.slug}?${param}`,
                    },
                  };
                  break;

                default:
                  queries = {
                    pathname: "/notification/[notificationSlug]",
                    query: {
                      ...utmQueries,
                      notificationSlug: elm.slug,
                      notificationId: elm.id,
                      url: "notification",
                    },
                  };
                  break;
              }
              return (
                <Link
                  key={elm.slug + "FieldNotif"}
                  onClick={() => {
                    trackEvent({
                      event: "notification_message_a",
                      property: {
                        type: elm.tag.type,
                        slug: elm.slug,
                      },
                    });
                  }}
                  href={queries}
                >
                  {/* @ts-ignore */}
                  <FieldWithBorder
                    {...elm}
                    withTag
                    isUsingButtonViewAll={isUsingButtonViewAll}
                  />
                </Link>
              );
            })
          ) : (
            <div className={isDesktop ? "pb-32" : ""}>
              <CardNotFound {...notFoundProps} />
            </div>
          )}

          {(allNotification as any)?.meta?.last_page > page && !isDropdown ? (
            <div className="p-16 flex justify-center">
              <Button
                isFull={!isDesktop}
                size="large"
                variant="secondary"
                state={notifApi.isLoading ? "loading" : "active"}
                onClick={onClickLoadMore}
              >
                Muat Lebih Banyak
              </Button>
            </div>
          ) : (
            <></>
          )}

          {isUsingButtonViewAll && (
            <Button
              variant="secondary_transparant"
              className="border-none font-bold bg-white_to_dark20"
              isFull
              onClick={() => {
                router.push({
                  pathname: "/notification",
                });
              }}
              size={isDesktop ? "large" : "small"}
            >
              Lihat Semua Notifikasi
            </Button>
          )}
        </>
      )}
    </div>
  );
};

export default NotificationContent;
