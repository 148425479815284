import React from "react";

type Props = {
  size?: number;
  color?: string;
  className?: string;
};

export const IconStatus: React.FC<Props> = ({
  size = 24,
  // color = "#19093A",
  className = "fill-neutral-80",
}) => {
  return (
    <svg
      width={size}
      height={size}
      // viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12 1C10.6234 1.00173 9.27794 1.40929 8.13179 2.17171C6.98564 2.93413 6.08973 4.01757 5.55612 5.28651C5.0225 6.55544 4.87487 7.95355 5.13169 9.30595C5.38851 10.6584 6.03838 11.905 7.00002 12.89V22C7.00053 22.1705 7.04461 22.338 7.12808 22.4866C7.21154 22.6352 7.33162 22.7601 7.4769 22.8492C7.62218 22.9384 7.78784 22.989 7.95816 22.9961C8.12847 23.0032 8.29779 22.9667 8.45002 22.89L12 21.12L15.55 22.89C15.6893 22.9614 15.8435 22.9991 16 23C16.1872 23.0006 16.3709 22.9486 16.53 22.85C16.6741 22.7599 16.7929 22.6346 16.8751 22.4859C16.9574 22.3372 17.0004 22.1699 17 22V12.89C17.9616 11.905 18.6115 10.6584 18.8683 9.30595C19.1252 7.95355 18.9775 6.55544 18.4439 5.28651C17.9103 4.01757 17.0144 2.93413 15.8682 2.17171C14.7221 1.40929 13.3766 1.00173 12 1V1ZM15 20.38L12.45 19.11C12.3104 19.0397 12.1563 19.003 12 19.003C11.8437 19.003 11.6896 19.0397 11.55 19.11L9.00002 20.38V14.32C9.63294 14.6189 10.3071 14.8211 11 14.92V16C11 16.2652 11.1054 16.5196 11.2929 16.7071C11.4804 16.8946 11.7348 17 12 17C12.2652 17 12.5196 16.8946 12.7071 16.7071C12.8947 16.5196 13 16.2652 13 16V14.92C13.6929 14.8211 14.3671 14.6189 15 14.32V20.38ZM12 13C11.0111 13 10.0444 12.7068 9.22216 12.1573C8.39992 11.6079 7.75906 10.827 7.38062 9.91342C7.00218 8.99979 6.90316 7.99445 7.09609 7.02455C7.28902 6.05464 7.76522 5.16373 8.46448 4.46447C9.16374 3.7652 10.0547 3.289 11.0246 3.09607C11.9945 2.90315 12.9998 3.00216 13.9134 3.3806C14.8271 3.75904 15.608 4.3999 16.1574 5.22215C16.7068 6.04439 17 7.01109 17 8C17 9.32608 16.4732 10.5979 15.5355 11.5355C14.5979 12.4732 13.3261 13 12 13V13Z"
        // fill={color}
      />
    </svg>
  );
};
