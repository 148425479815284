import { Dropdown } from "components/Dropdown/Dropdown";
import { IconBell } from "components/Icon/IconBell";
import { trackEvent } from "lib/amplitude";
import { useIsSubdomain, useIsTenant } from "lib/host";
import { useAuthStore } from "modules/Auth/authStore";
import { useGetNotificationApi } from "modules/Home/homeApi";
import NotificationContent from "modules/Notification/NotificationContent";
import { useNotifStore } from "modules/Notification/notifStore";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect, useRef, useState } from "react";

type Props = {
  isShowPopupNotif?: boolean;
  setIsShowPopupNotif?: (item: boolean) => void;
  className?: string;
  isIconTransparent?: boolean;
};

export const NotificationComponent: React.FC<Props> = ({
  isShowPopupNotif,
  setIsShowPopupNotif,
  className = "",
  isIconTransparent = false,
}) => {
  const isDesktop = typeof window !== "undefined" && window.innerWidth > 720;
  const isTenantURL = useIsTenant();
  const isSubdomainTenant = useIsSubdomain();
  const router = useRouter();
  const { loginState } = useAuthStore((state) => ({
    loginState: state.loginState,
  }));

  const notifications = useGetNotificationApi();
  // const isMounted = useRef(false);
  const { allNotification, setAllNotif } = useNotifStore((state) => ({
    allNotification: state.allNotification,
    setAllNotif: state.setAllNotif,
  }));

  useEffect(() => {
    if (loginState == "LOGIN") {
      notifications.doFetch(
        {},
        {
          onSuccess(res) {
            setAllNotif(res as any);
          },
          onError(err) {},
        }
      );
    }
  }, []);

  const trackEventClick = () => {
    const eventProperty = {
      page: router.pathname,
      is_opened: !isShowPopupNotif,
      total_unread_message: allNotification?.totalUnread,
    };

    trackEvent({
      event: "notification_open_a",
      property: eventProperty,
    });
  };

  const onClickNotification = () => {
    setIsShowPopupNotif(!isShowPopupNotif);
    trackEventClick();
  };

  const [timeoutNotif, setTimeoutNotif] = useState(null);

  const events = {
    event: "notification_hover_a",
    property: {
      page: router?.pathname,
    },
  };

  const mouseLeaveHandler = () => {
    // Hide hover content in mobile
    if (!isDesktop) return;
    timeoutNotif && clearTimeout(timeoutNotif);
    setIsShowPopupNotif(!isShowPopupNotif);
  };

  const mouseEnterHandler = () => {
    // Hide hover content in mobile
    if (!isDesktop) return;
    timeoutNotif && clearTimeout(timeoutNotif);
    setTimeoutNotif(
      setTimeout(() => {
        trackEvent({
          ...(events as any),
        });
      }, 1000)
    );
    setIsShowPopupNotif(!isShowPopupNotif);
  };

  const colorIcon = isIconTransparent ? "#551FC1" : "#FFFFFF";
  return (
    <Link
      //@ts-ignore
      href={"/notification"}
      onClick={trackEventClick}
    >
      <div className="group inline-block block relative text-left">
        <div
          className="w-32 h-32 relative flex items-center justify-center cursor-pointer"
          // onClick={onClickNotification}
          onMouseEnter={mouseEnterHandler}
          onMouseLeave={mouseLeaveHandler}
        >
          <IconBell className="m-auto" color={"#551FC1"} />

          {allNotification?.totalUnread !== "0" && (
            <div className="absolute text-c1 !text-[#FFF] bg-orange-50 top-[-2px] right-[-4px] rounded-full px-4">
              {allNotification?.totalUnread}
            </div>
          )}

          <Dropdown
            className="min-w-[400px] bg-white_to_dark20"
            theme={`py-16 ${isShowPopupNotif ? "!block" : "!hidden"}`}
            included={isSubdomainTenant || isTenantURL}
          >
            <NotificationContent isDropdown />
          </Dropdown>
        </div>
      </div>
    </Link>
  );
};
