import createStore from "zustand";
import { paths } from "lib/__generatedApiSpec";
import { OpReturnType } from "openapi-typescript-fetch";

type NotifData = OpReturnType<paths["/notification"]["get"]>;

export type NotifStore = {
  // state
  allNotification: NotifData;

  // actions
  setAllNotif: (data: any) => void;
};

export const useNotifStore = createStore<NotifStore>((set) => ({
  // state
  allNotification: [],

  // actions
  setAllNotif(data) {
    set({ allNotification: data });
  },
}));
