import createStore from "zustand";

export type LearningHourStore = {
  // state
  learningHour: string;
  courseLearningHour: string;

  // actions
  setLearningHour: (item: string) => void;
  setCourseLearningHour: (item: string) => void;
};

export const useLearningHourStore = createStore<LearningHourStore>((set) => ({
  // state
  learningHour: "",
  courseLearningHour: "",

  // actions
  setLearningHour(item) {
    set({ learningHour: item });
  },
  setCourseLearningHour(item) {
    set({ courseLearningHour: item });
  },
}));
