import { useSystemStore } from "components/PageHeader/systemStore";
import { trackEvent } from "lib/amplitude";
import Images from "next/image";
import { useEffect } from "react";

export type Props = {
  id?: string;
  onChange?: (a: any) => void;
  className?: string;
  checked?: boolean;
  selected?: string;
  isSwitch?: boolean;
  onUpdateTheme?: (a: any) => void;
};

const ToggleDarkMode: React.FC<Props> = ({
  className,
  onChange,
  id,
  checked,
  selected,
  isSwitch = false,
  onUpdateTheme,
}) => {
  const [theme, allThemes, setTheme, setAllTheme] = useSystemStore((state) => [
    state.theme,
    state.allThemes,
    state.setTheme,
    state.setAllTheme,
  ]);

  console.log("🚀 theme", theme);

  useEffect(() => {
    if (theme === "dark") {
      trackEvent({
        event: "dark_mode_s",
        property: {
          dark_mode: true,
        },
      });
    }
  }, [theme]);

  if (isSwitch) {
    return (
      <>
        <div className={`relative ${className || ""}`}>
          <label className="mb-0 block cursor-pointer" htmlFor={id}>
            <div
              className="flex gap-2 items-center h-32 bg-purple05_to_dark50 p-2 rounded-[100px]"
              onClick={() => {
                let oppositeTheme = theme == "light" ? "dark" : "light";
                localStorage.setItem("theme", oppositeTheme);
                setTheme(oppositeTheme);
                trackEvent({
                  event: "dark_mode_toggle_a",
                  property: {
                    theme: oppositeTheme,
                  },
                });
              }}
            >
              <div
                className={
                  (theme == "light" ? "bg-white_to_dark10" : "") +
                  " rounded-full p-4 flex items-center justify-center"
                }
              >
                <IconSun color={theme == "dark" ? "#FFF" : "#551FC1"} />
              </div>

              <div
                className={
                  (theme == "dark" ? "bg-white_to_dark10" : "") +
                  " rounded-full p-4 flex items-center justify-center"
                }
              >
                <IconMoon color={theme == "dark" ? "#FFF" : "#551FC1"} />
              </div>
            </div>
          </label>
        </div>
      </>
    );
  } else {
    return (
      <div
        className="w-36 h-36 p-4 items-center flex justify-center rounded-8 bg-purple-50 cursor-pointer"
        onClick={() => onChange(allThemes.find((a) => a.active)?.id)}
      >
        {
          {
            light: (
              <img
                src={"/images/icon-mode-light.svg"}
                alt="light"
                width={40}
                height={40}
              />
            ),
            dark: (
              <img
                src={"/images/icon-mode-dark.svg"}
                alt="light"
                width={40}
                height={40}
              />
            ),
            system: (
              <img
                src={"/images/icon-mode-system.svg"}
                alt="light"
                width={40}
                height={40}
              />
            ),
          }[allThemes.find((a) => a.active)?.id]
        }
      </div>
    );
  }
};

export default ToggleDarkMode;

export const IconSun = ({ color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99994 2.5V4.375M15.3033 4.69667L13.9774 6.0225M17.4999 10H15.6249M15.3033 15.3033L13.9774 13.9775M9.99994 15.625V17.5M6.02244 13.9775L4.69661 15.3033M4.37494 10H2.49994M6.02244 6.0225L4.69661 4.69667M13.1249 10C13.1249 10.8288 12.7957 11.6237 12.2096 12.2097C11.6236 12.7958 10.8287 13.125 9.99994 13.125C9.17114 13.125 8.37628 12.7958 7.79023 12.2097C7.20418 11.6237 6.87494 10.8288 6.87494 10C6.87494 9.1712 7.20418 8.37634 7.79023 7.79029C8.37628 7.20424 9.17114 6.875 9.99994 6.875C10.8287 6.875 11.6236 7.20424 12.2096 7.79029C12.7957 8.37634 13.1249 9.1712 13.1249 10Z"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const IconMoon = ({ color }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5013 10.0007C13.7088 10.331 12.8585 10.5005 11.9999 10.4994C8.40994 10.4994 5.49994 7.58938 5.49994 3.99938C5.49994 3.11271 5.67727 2.26805 5.99861 1.49805C4.81425 1.99212 3.80258 2.82562 3.09102 3.89355C2.37946 4.96149 1.99983 6.2161 1.99994 7.49938C1.99994 11.0894 4.90994 13.9994 8.49994 13.9994C9.78322 13.9995 11.0378 13.6199 12.1058 12.9083C13.1737 12.1967 14.0072 11.1851 14.5013 10.0007Z"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
